//  1-Local Testing
//  2- Development
//  3-Production

const MODE = 2

export let ApiServer
export let DonateToken
export let Donateenv
export let clientID

if (MODE === 1) {
  // Local API
  ApiServer = `http://172.25.5.215:8064/`
  DonateToken = "J7N8ZAE4MHTZC"
  Donateenv = "sandbox"
  clientID =
    "AcauJ3QBTXFgSNY_7BgKdmDcBC9VnYLS0jxn8Qf0kcd_-yEoDDRJ3JouqvAecRjPDUF6WP_lQfJKiIhq"
} else if (MODE === 2) {
  //Live DEVELOPMENT
  ApiServer = `https://satsdevapi.quasarpixels.com/`
  DonateToken = "J7N8ZAE4MHTZC"
  Donateenv = "sandbox"
  clientID =
    "AcauJ3QBTXFgSNY_7BgKdmDcBC9VnYLS0jxn8Qf0kcd_-yEoDDRJ3JouqvAecRjPDUF6WP_lQfJKiIhq"
} else if (MODE === 3) {
  //Live PRODUCTION
  ApiServer = `https://api.satamilsangam.org/`
  DonateToken = "5NYBREE3Q9PFG"
  Donateenv = "production"
  clientID =
    "ASUYOUhYDhbhO9dqoKI36bZm68UvgZml-GwgoeeZ80ijCMBdk_HK4K5bJZjEQZb1kKJTCPWQxHrYVbYe"
}

export default ApiServer

export const phoneNumber = "+1 (210) 865-3166"
