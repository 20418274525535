import React, { useEffect, useState } from "react"
import president from "../assets/images/TheSigningOfficers/selvagiri.jpg"
import { contactblog } from "../components/ContactBlog"
import { ToastContainer, toast } from "react-toastify"
import { Alert, Modal } from "react-bootstrap"
import { SlClose } from "react-icons/sl"
import swal from "sweetalert"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import PageBanner from "../layouts/PageBanner"
import { Label, FormFeedback, Input, Form, Col, Row } from "reactstrap"
import { Country } from "../components/country-state-city/countries"
import { State } from "../components/country-state-city/states"
import { CreateDonate, ResetBAM } from "../features/FormDataStore"
import { Link, useNavigate } from "react-router-dom"
import { fetchMyProfileData } from "../features/FetchData"
import DonateButton2 from "../components/Paypal/DonateButton2"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import { DonateToken } from "../helpers/Api_help"

// import App from "../components/Paypal/DonateButton"
const Donate = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()

  const User_Id_ = JSON.parse(localStorage.getItem("user_id"))
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  const { MembershipTypes, MyProfileInfo } = useSelector(
    (state) => state.FetchDataReducer
  )
  const { donateDetails, DonateSus, FailBAM } = useSelector(
    (state) => state.FormDataReducer
  )
  // console.log(contactblog)
  //   const [mannualAmount, setMannualAmount] = useState()

  useEffect(() => {
    console.log("userInfo", userInfo)
    if(userInfo)
    {
      dispatch(fetchMyProfileData(userInfo))
    }
  }, [])
  const [PaypalModal, setPaypalModal] = useState(false)
  //   const [donateDetails, SetDonatedetails] = useState()
  const Amount = [25, 50, 100, 500, 1000, "Any Other Amount"]
  const Donationfor = [
    "For Vidhya's Funeral Fund Support",
    "For SATS General Support - Donation",
    "COVID Relief for TN",
    "Houston Tamil Studies Chair",
    "Guard our Guardians - Donation",
    "For SATS Kuruchi Lake Rejuvenation - Donation",
  ]

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: MyProfileInfo?.firstname || "",
      lastname: MyProfileInfo?.lastname || "",
      email: MyProfileInfo?.email || "",
      mobile: MyProfileInfo?.mobile || "",
      address: MyProfileInfo?.address || "",
      address2: MyProfileInfo?.address2 || "",
      city: MyProfileInfo?.city || "",
      state: MyProfileInfo?.state || "",
      country_code: MyProfileInfo?.country_code || "",
      country: MyProfileInfo?.country || "",
      zipcode: MyProfileInfo?.zipcode || "",

      project_chapter: "",
      donate_amount: null,
      taxcheck: false,
    },
    validationSchema: Yup.object({
      // project_chapter: Yup.string().required(
      //   "Select the Project /Chapter You are donating to"
      // ),
      // donate_amount: Yup.string().required("Select the amount"),
      email: Yup.string()
        .transform((value) => value.replace(/\s/g, ""))
        .email("Invalid email address")
        .required("Email address is required"),
      firstname: Yup.string().required("First Name is required"),
      lastname: Yup.string().required("Last Name is required"),
      country: Yup.string().required("Select your Country"),
      state: Yup.string().required("Select your State"),
      city: Yup.string().required("City is required"),
      zipcode: Yup.string().required("Zipcode is required"),

      mobile: Yup.string()
        .matches(
          /^[0-9+\-\(\) ]{8,20}$/,
          "Invalid phone number,Example :+91 7289789898 ,+1 (201)78987678 (Enter country code and Mobile number)"
        )
        .required("Mobile Number is required"),
    }),
    onSubmit: (values, actions) => {
      setPaypalModal(true)
      values.donate_amount !== "Any Other Amount" &&
        values.taxcheck === true &&
        validation.setFieldValue("donate_amount", values.donate_amount)

      values.donate_amount === "Any Other Amount" &&
        validation.setFieldValue("taxcheck", false)

      //   SetDonatedetails(values)
      // nav()
      // window.location.href =
      //   "https://www.paypal.com/donate/?hosted_button_id=CFA4MGYERLHJG"
      dispatch(CreateDonate(values))
      console.log(values)
    },
  })

  useEffect(() => {
    if (DonateSus) {
      toast.warning("Donate Payment process start!", {
        position: "top-right",
      })

      // alert("Successfully")
      setPaypalModal(true)
      validation.resetForm()
    }
    if (FailBAM) {
      toast.error(donateDetails, {
        position: "top-right",
      })
      // alert(BAError)
    }

    dispatch(ResetBAM())
  }, [DonateSus])

  const filterstates = State.filter(
    (sta) => sta.country_name == validation.values.country
  )
  //

  const handleKeyDownonlynumber = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDownprice = (event) => {
    const inputValue = event.target.value

    // Allow numbers, Backspace, Delete, Arrow keys, etc.
    if (
      /^[0-9.]$/.test(event.key) || // Allow numbers and one dot
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight"
    ) {
      // Check if there's already a dot in the input
      if (inputValue.includes(".") && event.key === ".") {
        event.preventDefault() // Prevent adding another dot
      } else if (
        inputValue.includes(".") &&
        inputValue.split(".")[1].length >= 2 &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault() // Prevent more than two digits after the dot
      }
    } else {
      event.preventDefault() // Prevent other characters
    }
  }

  const handleKeyDownmobile = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[0-9+()\-\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDowncharcters = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[A-Za-z\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }

  const initialOptions = {
    "client-id":
      "AcauJ3QBTXFgSNY_7BgKdmDcBC9VnYLS0jxn8Qf0kcd_-yEoDDRJ3JouqvAecRjPDUF6WP_lQfJKiIhq",

    intent: "capture",
    currency: "USD",
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Donate" />

        <section className="content-inner gradient-white">
          <div className="container">
            <div className="row justify-content-center pb-3">
              <div className="col-xl-9 col-md-9 col-sm-12">
                <div className="">
                  <div className="contact-area">
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault()
                        validation.handleSubmit()
                      }}
                    >
                      <row>
                        <ul
                          className="text-dark p-3"
                          style={{ fontSize: "13px", lineHeight: 1.5 }}
                        >
                          <li>Connecting Good People With Good Causes!</li>
                          <li>
                            Make a 100% tax-deductible donation today to achieve
                            SATS missions. Make your donation today
                          </li>
                        </ul>
                      </row>
                      <Row className="col-12 d-flex flex-wrap justify-content-around">
                        <div className="row">
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <Label htmlFor="email">
                              First Name<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="firstname"
                              className={`form-control`}
                              placeholder="Enter First Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.firstname || ""}
                              onKeyDown={handleKeyDowncharcters}
                              invalid={
                                validation.touched.firstname &&
                                validation.errors.firstname
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.firstname &&
                            validation.errors.firstname ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.firstname}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <Label htmlFor="lastname">
                              Last Name<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="lastname"
                              className={`form-control`}
                              placeholder="Enter Last Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lastname || ""}
                              onKeyDown={handleKeyDowncharcters}
                              invalid={
                                validation.touched.lastname &&
                                validation.errors.lastname
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.lastname &&
                            validation.errors.lastname ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.lastname}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <Label htmlFor="email">
                              Email<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="email"
                              className={`form-control`}
                              placeholder="Enter Email Id"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <Label htmlFor="mobile">
                              Mobile Phone<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="mobile"
                              type="tel"
                              min={1}
                              className="form-control  "
                              placeholder="Enter Mobile Number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mobile || ""}
                              onKeyDown={handleKeyDownmobile}
                              invalid={
                                validation.touched.mobile &&
                                validation.errors.mobile
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mobile &&
                            validation.errors.mobile ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.mobile}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3 col-12">
                            <Label htmlFor="address">Address #1 Line</Label>
                            <Input
                              name="address"
                              className="form-control  "
                              placeholder="Enter Address #1"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.address || ""}
                              invalid={
                                validation.touched.address &&
                                validation.errors.address
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.address &&
                            validation.errors.address ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.address}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 col-12">
                            <Label htmlFor="homeadd2">Address #2 Line</Label>
                            <Input
                              name="address2"
                              className="form-control  "
                              placeholder="Enter Address #2"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.address2 || ""}
                              invalid={
                                validation.touched.address2 &&
                                validation.errors.address2
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.address2 &&
                            validation.errors.address2 ? (
                              <FormFeedback type="invalid">
                                {validation.errors.address2}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className=" mb-3 col-sm-12 col-md-4 col-lg-4">
                            <Label htmlFor="city">
                              City<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="city"
                              className="form-control  "
                              placeholder="Enter City Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.city || ""}
                              invalid={
                                validation.touched.city &&
                                validation.errors.city
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                            validation.errors.city ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                            <Label htmlFor="country">
                              Country<span className="text-danger">*</span>
                            </Label>
                            <select
                              id="country"
                              name="country"
                              className={`form-select ${
                                validation.touched.country &&
                                validation.errors.country &&
                                "is-invalid"
                              }`}
                              onChange={(e) => {
                                const V_code = Country.filter(
                                  (country) => country.name === e.target.value
                                )

                                validation.setFieldValue(
                                  "country",
                                  V_code?.[0].name
                                )
                                validation.setFieldValue(
                                  "country_code",
                                  V_code?.[0].iso2
                                )
                                validation.setFieldValue(
                                  "currency",
                                  V_code?.[0].currency
                                )
                              }}
                              value={validation.values.country}
                            >
                              <option value=" ">Select for a country </option>
                              {Country.map((country) => (
                                <option value={country.name} key={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            {validation.touched.country &&
                            validation.errors.country ? (
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                }}
                              >
                                {validation.errors.country}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                            <Label htmlFor="state">
                              State<span className="text-danger">*</span>
                            </Label>
                            <select
                              id="state"
                              name="state"
                              className={`form-select ${
                                validation.touched.state &&
                                validation.errors.state &&
                                "is-invalid"
                              }`}
                              onChange={(e) =>
                                validation.setFieldValue(
                                  "state",
                                  e.target.value
                                )
                              }
                              value={validation.values.state}
                            >
                              <option value=" ">Select for a State </option>
                              {filterstates.map((stat) => (
                                <option
                                  value={stat.name}
                                  key={stat.id}
                                  className="d-flex flex-grow-1"
                                >
                                  {stat.name}
                                </option>
                              ))}
                            </select>
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                }}
                              >
                                {validation.errors.state}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className=" mb-3 col-sm-12 col-md-4 col-lg-4">
                            <Label htmlFor="zipcode">
                              Zip Code <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="zipcode"
                              className="form-control  "
                              placeholder="Enter Zipcode"
                              type="tel"
                              min={1}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.zipcode || ""}
                              onKeyDown={handleKeyDownonlynumber}
                              invalid={
                                validation.touched.zipcode &&
                                validation.errors.zipcode
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.zipcode &&
                            validation.errors.zipcode ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.zipcode}
                              </FormFeedback>
                            ) : null}
                          </div>
                          {/* 
                          <div className=" mb-3 col-sm-12 col-md-4 col-lg-4">
                            <Label htmlFor="donate_amount">Amount </Label>
                            <Input
                              name="donate_amount"
                              className="form-control  "
                              placeholder="Enter donate_amount"
                              type="number"
                              min={1}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.donate_amount || ""}
                              onKeyDown={handleKeyDownonlynumber}
                              invalid={
                                validation.touched.donate_amount &&
                                validation.errors.donate_amount
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.donate_amount &&
                            validation.errors.donate_amount ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.donate_amount}
                              </FormFeedback>
                            ) : null}
                          </div>*/}
                        </div>

                        {/* <div className="row">
                          <div className="mb-3 col-sm-12 col-md-8 col-lg-8">
                            <Label htmlFor="project_chapter">
                              Project /Chapter You are donating to
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              id="project_chapter"
                              name="project_chapter"
                              className={`form-select ${
                                validation.touched.project_chapter &&
                                validation.errors.project_chapter &&
                                "is-invalid"
                              }`}
                              onChange={(e) => {
                                validation.setFieldValue(
                                  "project_chapter",
                                  e.target.value
                                )
                              }}
                              value={validation.values.project_chapter}
                            >
                              <option value={" "}>
                                Select for a Project /Chapter You are donating
                                to
                              </option>
                              {Donationfor.map((DF, ind) => (
                                <option value={DF} key={ind}>
                                  {DF}
                                </option>
                              ))}
                            </select>
                            {validation.touched.project_chapter &&
                            validation.errors.project_chapter ? (
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                }}
                              >
                                {validation.errors.project_chapter}
                              </div>
                            ) : null}
                          </div> */}
                        {/* <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                            <Label
                              htmlFor="donate_amount"
                              className=" input-group-append form-label"
                            >
                              Donation Amount
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              id="donate_amount"
                              name="donate_amount"
                              className={`form-select ${
                                validation.touched.donate_amount &&
                                validation.errors.donate_amount &&
                                "is-invalid"
                              }`}
                              onChange={(e) => {
                                validation.setFieldValue(
                                  "donate_amount",
                                  e.target.value
                                )
                              }}
                              value={validation.values.donate_amount}
                            >
                              <option value={" "}>Select Donate amount</option>
                              {Amount.map((amount, ind) => (
                                <option value={amount} key={ind}>
                                  {amount}
                                  {validation.values.taxcheck === true &&
                                    validation.values.donate_amount !==
                                      "Any Other Amount" &&
                                    "+(2.25% + 31 Cents)"}
                                </option>
                              ))}
                            </select>
                            {validation.touched.donate_amount &&
                            validation.errors.donate_amount ? (
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                }}
                              >
                                {validation.errors.donate_amount}
                              </div>
                            ) : null}
                          </div>
                        </div> */}

                        {/* {validation.values.donate_amount !==
                          "Any Other Amount" && (
                          <div className=" mb-2 ms-3 ps-5 col-11 form-check">
                            <Label htmlFor="taxcheck">
                              Donate 100% - Add (2.25% + 31 Cents) so that 100%
                              of the donation goes to SATS.
                            </Label>
                            <Input
                              name="taxcheck"
                              class="form-check-input check-sm"
                              type="checkbox"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.taxcheck || ""}
                              invalid={
                                validation.touched.taxcheck &&
                                validation.errors.taxcheck
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        )} */}
                      </Row>
                      <Col className="col-12 d-flex mt-3">
                        <div className=" text-end mt-3 col-12">
                          <button
                            type="submit"
                            className="btn btn-warning mx-3"
                          >
                            {"Proceed"}
                          </button>
                        </div>
                      </Col>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            <hr className="container my-2" />

            <div className="row justify-content-center pb-3 p-5">
              <p className="text-justify text-dark" style={{ fontSize: 20 }}>
                If writing a check for your donation, make your check payable to
                SA Tamil Sangam, write the name of The Project/Chapter you are
                contributing to in the memo section and mail your check to:
              </p>

              <div
                className="widget-title mt-1"
                style={{ paddingBottom: 0, marginBottom: 10 }}
              >
                <h5 className="title">Treasurer</h5>
              </div>
              <div className="avatar-wrapper">
                <div className="avatar-item text-dark fw-bold">
                  <ul>
                    <li>Mr Bharathi Rathinavel</li>
                    <li>4835 Usaa Blvd, San Antonio, Texas - 78240</li>
                    <li>sats@satamilsangam.org</li>
                    <li>Name of the Organization: SA Tamil Sangam</li>
                    <li>EIN # 81-0915793</li>
                  </ul>
                </div>
              </div>
            </div>

            <hr className="container my-2" />

            <div className="container mt-5 pb-5">
              <div className="dz-card style-4 bg-secondary ">
                <div className="dz-info" style={{ padding: 50 }}>
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-lg-6">
                      <div className="dz-meta">
                        <ul>
                          <li className="post-author">
                            <img
                              src={president}
                              alt=""
                              style={{
                                width: 80,
                                height: 80,
                                marginBottom: 10,
                              }}
                            />
                            <div style={{ lineHeight: 1, fontSize: 18 }}>
                              {" "}
                              Mr.Selvagiri Arunagiri{" "}
                            </div>
                            <div> President </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6">
                      <h5 className="dz-title">
                        <Link to={"/blog-details"} style={{ fontSize: 15 }}>
                          Contact us
                        </Link>
                      </h5>
                      <div className="avatar-wrapper">
                        {contactblog.map((item, ind) => (
                          <div className="avatar-item" key={ind}>
                            <div className="avatar-info">
                              <h6
                                className="title"
                                style={{
                                  fontSize: "15px",
                                  marginBottom: "2px",
                                }}
                              >
                                <Link
                                  to={"/contact-us"}
                                  style={{ color: "#fff" }}
                                >
                                  {item.title}
                                </Link>
                              </h6>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ToastContainer style={{ zIndex: "10999" }} />
        <Modal
          className="modal fade modal-wrapper auth-modal"
          show={PaypalModal}
          onHide={() => setPaypalModal(false)}
          centered
          backdrop="static"
        >
          <h2 className="title">
            Payment Donate Process{" "}
            <span
              className="position-absolute top-0 start-100 translate-middle text-light  rounded-circle"
              style={{
                fontSize: "2rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setPaypalModal(false)
              }}
            >
              <SlClose />
            </span>
          </h2>
          {/* {validation.values.donate_amount === "Any Other Amount" && (
            <input
              type="text"
              // onChange={(e) => setMannualAmount(e.target.value)}
              onKeyDown={handleKeyDownprice}
            ></input>
          )} */}

          {/* <DonateButton
            data2={donateDetails}
            setModal={setPaypalModal}
            // mannualAmount={mannualAmount}
          /> */}

          {/* <form
            action="https://www.sandbox.paypal.com/donate"
            method="post"
            target="_top"
          >
            <input type="hidden" name="hosted_button_id" value={DonateToken} />
            <input type="hidden" name="no_recurring" value="0" />
            <input type="hidden" name="item_name" value="Madhu Balan" />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="hidden" name="amount" value="60.00" />

            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img
              alt=""
              border="0"
              src="https://www.sandbox.paypal.com/en_US/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form> */}
          {/* <form
            action="https://www.sandbox.paypal.com/donate"
            method="post"
            target="_top"
          >
            <input type="hidden" name="hosted_button_id" value={DonateToken} />

            <input type="hidden" name="no_recurring" value="0" />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="hidden" name="amount" value="10.00" />
            <input type="hidden" name="first_name" value="John" />

            <input type="hidden" name="last_name" value="Doe" />
            <input type="hidden" name="address1" value="123 Main St" />
            <input type="hidden" name="address2" value="Apt 4B" />
            <input type="hidden" name="city" value="Anytown" />
            <input type="hidden" name="state" value="CA" />
            <input type="hidden" name="zip" value="12345" />
            <input type="hidden" name="country" value="US" />
            <input type="hidden" name="email" value="johndoe@example.com" />
            <input
              type="image"
              src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
              border="0"
              name="submit"
              title="PayPal - The safer, easier way to pay online!"
              alt="Donate with PayPal button"
            />
            <img
              alt=""
              border="0"
              src="https://www.sandbox.paypal.com/en_US/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form> */}

          {PaypalModal && (
            <DonateButton2
              data2={donateDetails}
              Modal={PaypalModal}
              setModal={setPaypalModal}
            />
          )}
        </Modal>
      </div>
    </>
  )
}

export default Donate
