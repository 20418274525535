import React from "react"
import { Link } from "react-scroll"

import PageBanner from "../../layouts/PageBanner"
//images
import { phoneNumber } from "../../helpers/Api_help"
import UpdateBlog from "../../components/Home/UpdateBlog"
import bg from "../../assets/images/banner/bnr4.jpg"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import { IMAGES } from "../../constant/theme"

const Membership = () => {

  const liStyle = {
    marginBottom:0,
    lineHeight:1.5
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Home"
          pagetitle="Benefits of Membership"
          background={bg}
        />
        <section className="content-inner-2 bg-light section-pattren1" style={{paddingTop:30}}>
          <div className="container text-justify">
            <div className="row dz-terms project-bx">
              <div className="col-xl-4 col-lg-5 m-b40 ">
                <aside className="side-bar sticky-top">
                  <div className="dz-terms-list">
                    <img src={IMAGES.membership}></img>
                    <ul className="navbar-nav navbar-scroll">
                      <li >
                        <Link
                          to="scrollNavSection01"
                          className="nav-link scroll"
                        >
                          Who can be an SATS Member?
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="scrollNavSection02"
                          className="nav-link scroll"
                        >
                          Benefits of Membership?
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="scrollNavSection03"
                          className="nav-link scroll"
                        >
                          Membership Fees?
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="scrollNavSection04"
                          className="nav-link scroll"
                        >
                          Duration of Membership?
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="scrollNavSection05"
                          className="nav-link scroll"
                        >
                          When to pay Membership?
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="scrollNavSection06"
                          className="nav-link scroll"
                        >
                          Mode of Payment?
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="scrollNavSection07"
                          className="nav-link scroll"
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
              <div className="col-xl-8 col-lg-7 ">
                <div className="dz-terms-content " id="scrollNavSection01">
                  <h4 className="title">Who can be an SATS Member?</h4>
                  <ul>
                    <li style={liStyle}>Any person with a minimum age of 18 years.</li>
                  </ul>
                </div>
                <div className="dz-terms-content" id="scrollNavSection02">
                  <h4 className="title">Benefits of Membership?</h4>
                  <ul>
                    <li style={liStyle}>
                      The following are just few of the benefits by becoming an
                      SATS member:
                    </li>
                    <li style={liStyle}>
                      Only members or their kids get to perform in all SATS
                      events.
                    </li>
                    <li style={liStyle}>
                      Members or their kids become eligible to publish articles
                      or publish their success story/achievements in
                      SATS-Newsletters.
                    </li>
                    {/* <li>Discounted fees for Tamil School Class.</li> */}
                  </ul>
                </div>
                <div className="dz-terms-content" id="scrollNavSection03">
                  <h4 className="title">Membership Fees?</h4>
                  <ul>
                    <li style={liStyle}>
                      Life Membership and Annual Family, and Student Membership
                      is available. Visit
                      <a
                        className="text-primary mx-2 gmail"
                        rel="noreferrer"
                        href="/becomemember"
                      >
                        Become a Member Page
                      </a>
                      see fee structure.
                    </li>
                  </ul>
                </div>
                <div className="dz-terms-content" id="scrollNavSection04">
                  <h4 className="title">Duration of Membership?</h4>
                  <ul>
                    <li style={liStyle}>
                      Annual Membership is collected for one President fiscal
                      Year runs from May 1st of one calendar year through April
                      30th. (For Example : All Annual Membership will end by
                      April and memebers have to renew by every May 1st of the
                      current President fiscal Period).
                    </li>
                  </ul>
                </div>
                <div className="dz-terms-content" id="scrollNavSection05">
                  <h4 className="title">When to pay Membership?</h4>
                  <ul>
                    <li style={liStyle}>
                      Members are highly encouraged to pay membership dues on
                      May 1st for every presidents fiscal year. The funds
                      collected earlier will also help SATS team to plan and
                      budget their annual events accordingly
                    </li>
                  </ul>
                </div>
                <div className="dz-terms-content" id="scrollNavSection06">
                  <h4 className="title">Mode of Payment?</h4>
                  <ul>
                    <li style={liStyle}>
                      Visit
                      <a
                        className="text-primary mx-2 gmail"
                        rel="noreferrer"
                        href="/becomemember"
                      >
                        Become a Member Page
                      </a>
                      more details.
                    </li>
                    <li style={liStyle}>By Paypal Services online.</li>
                  </ul>
                </div>
                <div className="dz-terms-content " id="scrollNavSection07">
                  <h4 className="title">Contact</h4>
                  <ul>
                    <li style={liStyle}>
                      By Mailing the check payable to “SA Tamil Sangam”. Enclose
                      the completed PDF form and mail to:
                      <br />
                      <p className="fw-bold" style={{ lineHeight: 1.5 }}>
                      Bharathi Rathanavel
                        <br />
                        4835 Usaa Blvd
                        <br /> San Antonio, TX 78240
                      </p>
                    </li>
                    <li style={liStyle}>
                      Pay personally by cash or check to one of our Executive
                      committee members. You can reach us at any of our annual
                      events or by email/phone listed on the
                      {/* <span className="mx-2">{phoneNumber}</span> */}
                      <a
                        className="text-primary mx-2 fw-bold gmail"
                        rel="noreferrer"
                        href="/contact-us"
                      >
                        Contact
                      </a>
                      menu
                      {/* <strong>
                        <a
                          className="text-primary mx-2 gmail"
                          target="_blank"
                          rel="noreferrer"
                          href="mailto:sats@satamilsangam.org"
                        >
                          sats@satamilsangam.org
                        </a>
                      </strong> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <img src={shape5} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape6} className="shape-3 move-1" alt="shape" />
          <img src={shape1} className="shape-4 rotating" alt="shape" />
          <img src={shape5} className="shape-5 rotating" alt="shape" />
          <img src={shape3} className="shape-6 rotating" alt="shape" />
        </section>
      </div>
    </>
  )
}

export default Membership
